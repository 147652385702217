import React, { Component, Fragment, useState } from "react"
import { Link, graphql } from "gatsby"
import NewsEventList from "../layouts/news-event-template"
import CardNewsEvent from "../components/custom-components/cardNewsEvent"
import "../css/custom-css/custom-news.css"
import Seo from "../components/seo"

const BlogGrid3Sidebar = props => {
  // {search}
  const { data } = props
  const allPosts = data.getAllNewsEvents.edges
  const [searchState, setSearchState] = useState(false)

  const [state, setState] = useState({
    filteredData: [],
    query: "",
  })

  const handleInputChange = event => {
    const query = event.target.value
    if (query === "") {
      setSearchState(false)
    } else {
      const posts = data.getAllNewsEvents.edges || []
      const filteredData = posts.filter(post => {
        const { title } = post.node
        return title.toLowerCase().includes(query.toLowerCase())
      })

      setState({
        query,
        filteredData,
      })
      setSearchState(true)
    }
  }

  // {pagination}
  const { currentPage, newsEventNumPages } = props.pageContext
  const prevPage =
    currentPage - 1 === 1
      ? `/news-page/news-event`
      : `/news-page/news-event${currentPage - 1}`
  const nextPage = `/news-page/news-event${currentPage + 1}`

  const isFirst = currentPage === 1
  const isLast = currentPage === newsEventNumPages

  return (
    <Fragment>
      <Seo title="News and Events" />

      <NewsEventList handleInputChange={handleInputChange}>
        <div class="row">
          {searchState
            ? state.filteredData.map(({ node }) => {
                return <CardNewsEvent key={data.id} data={node} />
              })
            : data.newsPost.edges.map(({ node }) => {
                return <CardNewsEvent key={data.id} data={node} />
              })}
        </div>

        <div class="pagination-bx clearfix m-b30 text-center col-md-12">
          {!searchState && (
            <ul class="pagination">
              <li class="previous">
                {!isFirst && (
                  <Link to={prevPage}>
                    <i class="ti-arrow-left"></i> Previous
                  </Link>
                )}
              </li>
              <li>
                {Array.from({ length: newsEventNumPages }, (_, i) => {
                  return (
                    <Link
                      key={i}
                      to={`/news-page/news-event${i === 0 ? "" : i + 1}`}
                      //   className={
                      //     i + 1 === currentPage
                      //       ? `${styles.link} ${styles.active}`
                      //       : `${styles.link}`
                      //   }
                    >
                      {i + 1}
                    </Link>
                  )
                })}
              </li>
              <li class="next">
                {!isLast && (
                  <Link to={nextPage}>
                    <i class="ti-arrow-right"></i> Next
                  </Link>
                )}
              </li>
            </ul>
          )}
        </div>
      </NewsEventList>
    </Fragment>
  )
}
export default BlogGrid3Sidebar

export const query = graphql`
  query getNewsEventPost($skip: Int!, $limit: Int!) {
    newsPost: allContentfulNewsevents(
      skip: $skip
      limit: $limit
      sort: { fields: newsEventsDate, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          id: contentful_id
          descSingkat
          createdAt(formatString: "DD MMMM YYYY")
          newsEventsDate(formatString: "DD MMMM YYYY")
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
            gatsbyImageData(layout: CONSTRAINED, height: 305, width: 457)
          }
        }
      }
    }
    getAllNewsEvents: allContentfulNewsevents {
      edges {
        node {
          slug
          title
          id: contentful_id
          descSingkat
          createdAt(formatString: "DD MMMM YYYY")
          newsEventsDate(formatString: "DD MMMM YYYY")
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
            gatsbyImageData(layout: CONSTRAINED, height: 305, width: 457)
          }
        }
      }
    }
  }
`

// export const newsEventQuery = graphql`
// {
//   allContentfulNewsevents {
//     nodes {
//       id
// 	  title
// 	  descSingkat
// 	  createdAt(formatString: "DD MMMM YYYY")
//       images {
//         gatsbyImageData
//       }
//       slug
//       description {
//         raw
//       }
//       newsType
//       eventsType
//     }
//   }
// }
// `
